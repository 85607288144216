const { VITE_APP_MOZHE_HOME_URL } = process.env;

export default function RedirectToHome({ to, isCustomDomain }) {
  const toAbsolute = `${
    to
    ? `/${to}`
    : ''
  }`;
  if (isCustomDomain) {
    window.location.href = `${window.location.origin}${toAbsolute}`;
  } else {
    window.location.href = `${VITE_APP_MOZHE_HOME_URL}${toAbsolute}`;
  }
  return null;
}
