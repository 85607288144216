import React from 'react';
import { createRoot } from 'react-dom/client'
import { parse as parseQuery } from 'querystring';

import App from './App';

import Types from './modules/types';

const {
  VITE_APP_SERVER_HOST,
  VITE_APP_SERVER_PROTOCOL,
  VITE_APP_HTTP_PORT,
  VITE_APP_API_PATH,
  VITE_APP_MOZHE_HOME_URL,
} = process.env;

// alert(`AAA: ${VITE_APP_SERVER_HOST} ${JSON.stringify(process.env)}`);

const SERVER_URL = `${
  VITE_APP_SERVER_PROTOCOL
}://${
  VITE_APP_SERVER_HOST
}${
  VITE_APP_HTTP_PORT === '80' ? '' : `:${VITE_APP_HTTP_PORT}`
}`;

const API_URL = `${SERVER_URL}${VITE_APP_API_PATH}`;

const IS_CUSTOM_DOMAIN = Types.getIsCustomDomain(window.location.hostname);

const searchParams = parseQuery(window.location.search.slice(1));

createRoot(window.document.getElementById('root')).render(
  <App
    serverUrl={SERVER_URL}
    apiUrl={API_URL}
    homeUrl={VITE_APP_MOZHE_HOME_URL}
    isCustomDomain={IS_CUSTOM_DOMAIN}
    iframe={`${searchParams.iframe}` === 'true'}
    token={`${searchParams.token}`}
  />,
);
