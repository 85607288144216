import React, {
  createContext,
  useContext,
  useMemo,
} from 'react';
import {
  stringify as stringifyQuery,
} from 'querystring';

import { useApiRequest } from './Api';
import storage, { session as sessionStorage } from '../storage';

const Context = createContext();

export default Context;

export function Provider(props) {
  // const [state] = useApiRequest({
  //   url: `users/me?${stringifyQuery({
  //     query: JSON.stringify({ populate: { store: true } }),
  //   })}`,
  // });
  const finalState = useMemo(
    () => {
      const state = {
        loading: false,
        data: null,
        error: new Error(),
      };
      return {
        setData: (key, value, forceLocalStorage = false) => {
          try {
            return (
              (!forceLocalStorage && state.data) ? sessionStorage : storage
            )
            .setItem(
              `${
                state.data
                ? `${state.data._id}.`
                : ''
              }${
                key
              }`,
              JSON.stringify(value),
            );
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error.message);
            return null;
          }
        },
        getData: (key, forceLocalStorage = false) => {
          try {
            return JSON.parse(
              (
                (!forceLocalStorage && state.data) ? sessionStorage : storage
              )
              .getItem(`${
                state.data
                ? `${state.data._id}.`
                : ''
              }${
                key
              }`)
            );
          } catch (error) {
            return null;
          }
        },
        loading: false,
        data: null,
        error: new Error(),
      };
      // ...state,
    },
    [],
  );
  if (finalState.loading) {
    return null;
  }
  return (
    <Context.Provider
      value={finalState}
      {...props}
    />
  );
}

export const { Consumer } = Context;

export function useViewer() {
  const viewer = useContext(Context);
  return viewer;
}

export function useIsViewerRole(role) {
  const viewer = useViewer();
  if (!viewer || !viewer.data) {
    return null;
  }
  return viewer?.data?.role === role;
}
