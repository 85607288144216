import isUndefined from 'lodash/isUndefined';
import isPlainObject from 'lodash/isPlainObject';

const Types = {};

// Types.getEnv = (name) => (
//   process.env[name]
//   || process.env[`NEXT_PUBLIC_${name}`]
//   || process.env[`VITE_APP_${name}`]
// );

// Types.getEnvs = (names) => names.map(name => Types.getEnv(name));

// Types.getEnvsMap = (names) => {
//   const values = Types.getEnvs(names);
//   return names.reduce(
//     (agr, name, i) => {
//       agr[name] = values[i];
//       return agr;
//     },
//     {},
//   );
// };

Types.VAT = 0.2;

Types.createSlug = (...values) => (
  values
  .join('-')
  .trim()
  .toLowerCase()
  .replace(/[^a-z0-9-_]+/g, '-')
  .replace(/(?:(-)[-_]*)|(?:(_)[-_]*)/g, '$1$2')
  .replace(/(?:^[-_]+)|(?:[-_]+$)/g, '')
);

Types.getCalculatedPriceWithoutVat = (value, vatRate = Types.VAT) => {
  if (Number.isFinite(value)) {
    const vat = Types.getRoundedAmount((value / (1 + vatRate)) * vatRate);
    const withoutVat = Types.getRoundedAmount(value - vat);
    return withoutVat;
  }
  return undefined;
};

Types.getSafeSearchRegex = value => (
  `${value || ''}`
  .replace(/[|\\{}()[\]^$+*\-?.]/g, '\\$&')
  .replace(/[cćč]/gi, '[cćč]')
  .replace(/[sš]/gi, '[sš]')
  .replace(/(?:zh|z|ž)/gi, '(?:zh|z|ž)')
  .replace(/(?:dj|d|đ)/gi, '(?:dj|d|đ)')
);

Types.decimalize = (
  number,
  decimalSeparator = '.',
  rounding = 2,
  thousandSeparator,
) => {
  if (isUndefined(thousandSeparator)) {
    thousandSeparator = decimalSeparator === '.' ? ',' : '.';
  }
  return number.toFixed(rounding)
  .split('')
  .reverse()
  .join('')
  .split('.')
  .map((value, i, values) => (
    (values.length === 2 && i === 1) || i === 0
    ? value
      .replace(/(.{3})/g, `$1${thousandSeparator}`)
      .replace(new RegExp(`\\${thousandSeparator}$`, 'g'), '')
    : value
  ))
  .join(decimalSeparator)
  .split('')
  .reverse()
  .join('');
};

// Types.decimalize = (number, decimalSeparator = '.') => {
//   const thousandSeparator = decimalSeparator === '.' ? ',' : '.';
//   return number.toFixed(2)
//     .split('')
//     .reverse()
//     .join('')
//     .split('.')
//     .map((value, i) => (
//       i === 1
//         ? value
//           .replace(/(.{3}-?)/g, `$1${thousandSeparator}`)
//           .replace(new RegExp(`\\${thousandSeparator}$`, 'g'), '')
//         : value
//     ))
//     .join(decimalSeparator)
//     .split('')
//     .reverse()
//     .join('');
// };

Types.decimalizeInt = (number, decimalSeparator) => Types.decimalize(
  number,
  decimalSeparator,
  0,
);

Types.getRoundedAmount = (amount, rounding = 2) => {
  const factor = 10 ** rounding;
  return Math.round(amount * factor) / factor;
};

Types.getRoundedAmountWithRounding = (amount, precision = 0.011) => {
  const roundedAmount = Types.getRoundedAmount(amount);
  const diff = amount - roundedAmount;
  if (diff < precision) {
    return Math.round(amount);
  }
  return amount;
};

// eslint-disable-next-line no-unused-vars
Types.getCalculatedPriceIncludingVat = (value) => {
  console.log('Types.VAT:', Types.VAT);
  if (Number.isFinite(value)) {
    const vat = Types.getRoundedAmount(value * Types.VAT);
    const includingVat = Types.getRoundedAmount(value + vat);
    return includingVat;
  }
  return undefined;
};

// eslint-disable-next-line no-unused-vars
Types.getCalculatedPriceWithoutVat = (value) => {
  if (Number.isFinite(value)) {
    const vat = Types.getRoundedAmount((value / (1 + Types.VAT)) * Types.VAT);
    const withoutVat = Types.getRoundedAmount(value - vat);
    return withoutVat;
  }
  return undefined;
};

Types.createValues = (
  name,
  values = [],
  config = {},
) => {
  config.keyId = config.keyId || 'id';
  config.getId = config.getId || (value => value[config.keyId]);
  config.keyLabel = config.keyLabel || 'label';
  config.getLabel = config.getLabel || (value => value[config.keyLabel]);
  // config.getExtras = config.getExtras;
  const {
    // keyId,
    getId,
    // keyLabel,
    getLabel,
    getExtras,
  } = config;
  const result = values.reduce(
    (agr, value) => {
      const id = getId(value);
      const label = getLabel(value);
      agr[name] = agr[name] || [];
      agr[name].push(id);
      const nameList = `${name}_LIST`;
      const nameItems = `${name}_ITEMS`;
      agr[nameList] = agr[nameItems] = (
        agr[nameList]
        || agr[nameItems]
        || []
      );
      agr[nameList].push(value);
      const nameMap = `${name}_MAP`;
      agr[nameMap] = agr[nameMap] || {};
      agr[nameMap][id] = value;
      const nameLabels = `${name}_LABELS`;
      agr[nameLabels] = agr[nameLabels] || [];
      agr[nameLabels].push(label);
      const nameLabelsMap = `${name}_LABELS_MAP`;
      agr[nameLabelsMap] = agr[nameLabelsMap] || {};
      agr[nameLabelsMap][id] = label;
      const nameConstants = `${name}_CONST`;
      agr[nameConstants] = agr[nameConstants] || {};
      agr[nameConstants][id] = id;
      return agr;
    },
    {},
  );
  if (getExtras) {
    getExtras(result, name, values, config);
  }
  return result;
};

Types.createValues.withAssignedTypes = (
  name,
  values = [],
  config = {},
) => {
  config = config || {};
  config.keyId = config.keyId || 'id';
  config.getId = config.getId || (value => value[config.keyId]);
  return Types.createValues(
    name,
    values.map(value => ({
      ...value,
      type: value.type || config.getId(value),
    })),
    config,
  );
};

Types.createConstant = valuesArray => valuesArray.reduce(
  (agr, value) => {
    agr[value] = value;
    return agr;
  },
  {},
);

// Types.createValues.withAssignedTypes = (
//   name,
//   values = [],
//   config = {},
// ) => {
//   config = config || {};
//   config.keyId = config.keyId || 'id';
//   config.getId = config.getId || (value => value[config.keyId]);
//   return Types.createValues(
//     name,
//     values.map(value => ({
//       ...value,
//       type: value.type || config.getId(value),
//     })),
//     config,
//   );
// };

Types.findPaths = (obj, name, testCallback, currentPath) => {
  currentPath = currentPath || '';
  let paths = [];
  if (!obj || typeof obj !== 'object') {
    return undefined;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(obj)) {
    if (key === name && testCallback(obj[key], obj)) {
      paths.push([currentPath, obj]);
    } else {
      const newPaths = Types.findPaths(
        obj[key],
        name,
        testCallback,
        `${currentPath}['${key}']`,
      );
      if (newPaths && newPaths.length) {
        paths = [
          ...paths,
          ...newPaths,
        ];
      }
    }
  }
  return paths;
};

Types.findPaths.equals = (val) => {
  const testCallback = value => value === val;
  return (obj, name) => Types.findPaths(
    obj,
    name,
    testCallback,
  );
};

Types.TraverseInfiniteRecursionError = (
  class TraverseInfiniteRecursionError extends Error {
    constructor(message = 'Exceeded maximum path length') {
      super(message);
      this.name = 'TraverseInfiniteRecursionError';
    }
  }
);

Types.traverse = (
  input,
  callback,
  cache = {},
  path = [],
) => {
  path = path || [];
  if (path.length > 200) {
    throw new Types.TraverseInfiniteRecursionError(
      `Detected infinite recursion (path length: ${path.length})`,
    );
  }
  // if (!input || typeof input !== 'object') {
  //   return;
  // }
  cache = { ...cache };
  const shouldStop = callback(
    input,
    path,
    cache,
    callback,
    Types.traverse,
  );
  if (shouldStop) {
    return;
  }
  if (input && typeof input === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const inputKey of Object.keys(input)) {
      const newPath = [...path, inputKey];
      // const newPath = `${path}${path.length ? `.${inputKey}` : inputKey}`;
      const newInput = input[inputKey];
      // if (newInput && typeof newInput === 'object') {
        Types.traverse(
          newInput,
          callback,
          cache,
          newPath,
        );
      // }
    }
  }
};

Types.traverseAsync = async (
  input,
  callback,
  cache = {},
  path = [],
) => {
  path = path || [];
  if (path.length > 200) {
    throw new Types.TraverseInfiniteRecursionError(
      `Detected infinite recursion (path length: ${path.length})`,
    );
  }
  // if (!input || typeof input !== 'object') {
  //   return;
  // }
  cache = { ...cache };
  let shouldStop = false;
  if (isPlainObject(input)) {
    shouldStop = await callback(
      input,
      path,
      cache,
      callback,
      Types.traverseAsync,
    );
  }
  if (shouldStop) {
    return;
  }
  if (input && typeof input === 'object') {
    await Promise.all(Object.keys(input).map(async (inputKey) => {
      const newPath = [...path, inputKey];
      // const newPath = `${path}${path.length ? `.${inputKey}` : inputKey}`;
      const newInput = input[inputKey];
      // if (newInput && typeof newInput === 'object') {
        await Types.traverseAsync(
          newInput,
          callback,
          cache,
          newPath,
        );
      // }
    }));
  }
};

[
  Types.traverse,
  Types.traverseAsync,
].forEach((traverseFn) => {
  traverseFn.objects = (
    input,
    callback,
    ...restArgs
  ) => traverseFn(
    input,
    (callbackInput, ...restCallbackArgs) => {
      if (isPlainObject(callbackInput)) {
        return callback(callbackInput, ...restCallbackArgs);
      }
      return false;
    },
    ...restArgs,
  );
});

export default Types;
