import isUndefined from 'lodash/isUndefined';

import TypesHelpers from './types.helpers';
import TypesInternationalization from './types.internationalization';
import TypesSystemCountry from './types.system-country';
import TypesLanguageDicrionaryKeys from './types.language-dictionary-keys';
import TypesStoreColorContexts from './types.store-color-contexts';
import TypesShippingCompanies from './types.shipping-companies';
import TypesContentBuilder from './types.content-builder';
import TypesGallery from './types.gallery';
import TypesArModels from './types.armodels';

import dexpressStatus from '../assets/dexpressStatus.json';
import glsStatus from '../assets/glsStatus.json';
import dictionary from '../assets/dictionary.json';

const prettifyString = string => (
  `${string}`
  .replace(/([A-Z])|\.([a-z])/g, ' $1')
  .replace(/[_-]/g, '')
  .replace(/^./, str => str.toUpperCase())
  .replace(/Id/g, 'ID')
);

const Types = {
  ...TypesHelpers,
  ...TypesInternationalization,
  ...TypesSystemCountry,
  ...TypesLanguageDicrionaryKeys,
  ...TypesStoreColorContexts,
  ...TypesShippingCompanies,
  ...TypesContentBuilder,
  ...TypesGallery,
  ...TypesArModels,
};

Types.STORE_OID_TOKEN_KEY = 'STORE_OID_TOKEN';
Types.STORE_OID_TOKEN_SEARCH_PARAM = 'storeoidtoken';

Types.SHOP_PRODUCTS_ARCHIVE_PATH = 'shop';

Object.assign(
  Types,
  Types.createValues('ROLE', [{
    id: 'ADMIN',
    label: 'Admin',
  }, {
    id: 'SELLER',
    label: 'Seller',
  }]),
  Types.createValues('STORE_TYPE', [{
    id: 'MERCHANT',
    label: 'Merchant',
  }, {
    id: 'REFERRAL',
    label: 'Referral',
  }, {
    id: 'RESELLER',
    label: 'Reseller',
  }].map(storeType => ({
    ...storeType,
    productType: storeType.productType || `${storeType.id}_PRODUCT`,
  }))),
  Types.createValues('CHANGE_LOG_ACTION', [{
    id: 'CREATE',
    label: 'Create',
  }, {
    id: 'PATCH',
    label: 'Update',
  }, {
    id: 'DELETE',
    label: 'Delete',
  }, {
    id: 'PRODUCTS_STOCK_UPDATE',
    label: 'Stock Update',
  }]),
  Types.createValues('CHANGE_LOG_TARGET_TYPE', [{
    id: 'Store',
    links: {
      ADMIN: '/stores',
      SELLER: '/stores',
    },
  }, {
    id: 'Language',
    links: {
      ADMIN: '/languages',
    },
  }, {
    id: 'DiscountCode',
    links: {
      ADMIN: '/discountcodes',
      SELLER: '/store/discountcodes',
    },
  }, {
    id: 'Product',
    links: {
      ADMIN: '/products',
      SELLER: '/store/products',
    },
  }, {
    id: 'Category',
    links: {
      ADMIN: '/categories',
      SELLER: '/store/categories',
    },
  }, {
    id: 'Collection',
    links: {
      ADMIN: '/collections',
      SELLER: '/store/collections',
    },
  }, {
    id: 'Page',
    links: {
      ADMIN: '/website/pages',
      SELLER: '/store/website/pages',
    },
  }, {
    id: 'ContentBuilderSegment',
    links: {
      ADMIN: ({ target }) => {
        let linkBase = null;
        switch (target?.subject?.type) {
          case 'COMPONENT':
            linkBase = '/website/components';
            break;
          case 'COMPONENT_SET':
            linkBase = '/website/components-set';
            break;
          case 'BLOCK':
            linkBase = '/website/blocks';
            break;
          case 'LIBRARY_ITEM':
            linkBase = '/website/library';
            break;
          default:
            linkBase = null;
            break;
        }
        if (!linkBase) {
          return null;
        }
        return `${linkBase}/${
          target.subject?._id || target.subject
        }`;
      },
    },
  }, {
    id: 'Document',
    links: {
    ADMIN: '/documents',
      SELLER: '/documents',
    },
  }, {
    id: 'Settings',
    links: {
      ADMIN: (record) => {
        let suffix = null;
        switch (record.target?.name) {
          case 'productsUploadBatch':
            suffix = 'product-upload-batch';
            break;
          case 'productGallerySort':
            suffix = 'product-gallery-sort';
            break;
          case 'productGalleryGenerate':
            suffix = 'product-gallery-generate';
            break;
          case 'contentBuilderLibraryGenerate':
            suffix = 'content-builder-library-generate';
            break;
          case 'magicText':
            suffix = 'magic-text';
            break;
          default:
            suffix = record.target?.name;
            break;
        }
        return `/settings/${suffix}`;
      },
    },
    getLabel: (label) => prettifyString(label),
  }, {
    id: 'Pricing',
    links: {
      ADMIN: '/settings',
    },
  }, {
    id: 'ShippingCompany',
    links: {
      ADMIN: '/shipping/companies',
    },
  }, {
    id: 'ShoppingAssistant',
    links: {
      ADMIN: '/website/shopping-assistants',
      SELLER: '/website/store/shopping-assistants',
    },
  }].map((item) => {
    item.label = item.id;
    return item;
  })),
  (() => {
    const result = Types.createValues('LAYOUT_SECTION', [{
      id: 'announcements',
      label: 'Announcements',
    }, {
      id: 'header',
      label: 'Header',
    }, {
      id: 'cart',
      label: 'Cart',
      modes: ['page'],
    }, {
      id: 'sidebar',
      label: 'Sidebar',
      modes: ['shop'],
    }, {
      id: 'footer',
      label: 'Footer',
      disabled: (props) => props.viewer.role !== 'ADMIN',
    }].map(item => ({
      name: item.id,
      modes: ['page', 'shop'],
      disabled: () => false,
      ...item,
    })));
    return Object.assign(
      result,
      Types.createValues(
        'LAYOUT_SECTION_PAGE',
        result.LAYOUT_SECTION_ITEMS.reduce(
          (agr, item) => {
            if (item.modes.includes('page')) {
              agr.push(item);
            }
            return agr;
          },
          [],
        ),
      ),
      Types.createValues(
        'LAYOUT_SECTION_SHOP',
        result.LAYOUT_SECTION_ITEMS.reduce(
          (agr, item) => {
            if (item.modes.includes('shop')) {
              agr.push(item);
            }
            return agr;
          },
          [],
        ),
      ),
    );
  })(),
  Types.createValues('STORE_SUBSCRIPTION_DURATION', [{
    id: '1_MONTH',
    label: '1 Month',
    labelShort: '1m',
    labelPeriod: 'a month',
    labelExpires: 'A month from now',
    value: 1,
    unit: 'month',
  }, {
    id: '6_MONTHS',
    label: '6 Months',
    labelShort: '6m',
    labelPeriod: 'every 6 months',
    labelExpires: '6 months from now',
    value: 6,
    unit: 'month',
  }, {
    id: '1_YEAR',
    label: '1 Year',
    labelShort: '1y',
    labelPeriod: 'a year',
    labelExpires: 'A year from now',
    value: 1,
    unit: 'year',
  }]),
  Types.createValues('STORE_SUBSCRIPTION_MODE', [{
    id: 'OFFLINE',
    label: 'Offline',
  }, {
    id: 'ONLINE',
    label: 'Online',
  }]),
  Types.createValues('STORE_CATEGORIES', [
    'Electronics',
    'Fashion',
    'Perfumes',
    'Beauty & Skin Care',
    'Baby Products',
    'Educational Products',
    'Toys',
    'Home Appliances',
    'Other',
  ].map(label => ({
    id: label.toUpperCase().replace(/\s+/g, '_'),
    label,
  }))),
  Types.createValues('PRODUCT_KIND', [{
    id: 'TANGIBLE',
    label: 'Product',
  }, {
  //   id: 'DIGITAL',
  //   label: 'Digital',
  //   abstract: true,
  // }, {
    id: 'SERVICE',
    label: 'Service',
    abstract: true,
  }]),
  Types.createValues('PRODUCT_OPTION_TYPE', [{
    id: 'CHOICE',
    label: 'Choices',
  }, {
    id: 'TEXT',
    label: 'Text',
  }, {
    id: 'COLOR',
    label: 'Color',
  }, {
    id: 'IMAGE',
    label: 'Image',
  }]),
  Types.createValues('CSV_FORMAT', [{
    id: 'GENERAL',
    label: 'General',
    delimiters: {
      quote: '"',
      column: ',',
      row: '\n',
      decimal: '.',
    },
  }, {
    id: 'EUROPEAN',
    label: 'European',
    delimiters: {
      quote: '"',
      column: ';',
      row: '\n',
      decimal: ',',
    },
  }, {
    id: 'INTERNATIONAL',
    label: 'International',
    delimiters: {
      quote: '"',
      column: ',',
      row: '\n',
      decimal: '.',
    },
  }]),
);

Types.PRODUCT_KIND_ABSTRACT = Types.PRODUCT_KIND.filter(kind => (
  Types.PRODUCT_KIND_MAP[kind].abstract === true
));

Types.RESELLER_COMMISSION_PERCENTAGE_MIN = 20;
Types.RESELLER_COMMISSION_PERCENTAGE_MAX = 80;
Types.RESELLER_COMMISSION_PERCENTAGE_DEFAULT = 20;

Types.RESELLER_ADDED_VALUE_PERCENTAGE_MIN = 0;
Types.RESELLER_ADDED_VALUE_PERCENTAGE_MAX = 50;
Types.RESELLER_ADDED_VALUE_PERCENTAGE_DEFAULT = 0;

Types.STORE_PAGE_PATH_TAC = 'terms-and-conditions';
Types.STORE_PAGE_PATH_SARP = 'shipping-and-return-policy';

Types.FORBIDDEN_SKUS = [
  Types.STORE_PAGE_PATH_TAC,
  Types.STORE_PAGE_PATH_SARP,
];

Types.MOZHE_STORE_HOSTNAMES = (() => {
  const hostnames = (
       process.env.MOZHE_STORE_HOSTNAMES
    || process.env.VITE_APP_MOZHE_STORE_HOSTNAMES
    || process.env.REACT_APP_MOZHE_STORE_HOSTNAMES
  );
  return [
    'localhost',
    ...(hostnames ? hostnames.split(',') : []),
  ];
})();

Types.getIsLocalSubnet = (hostname) => (
  ['localhost', '127.0.0.1'].includes(hostname)
  || /^192\.168\.\d{1,3}\.\d{1,3}$/.test(hostname)
);

Types.getIsCustomDomain = (hostname) => (
  !Types.MOZHE_STORE_HOSTNAMES.includes(hostname)
  && !Types.getIsLocalSubnet(hostname)
);

Types.FORBIDDEN_SLUGS = [
  ...Object.keys(Types.LANGUAGES_MAP),
];

Types.FORBIDDEN_SLUGS_PAGE = [
  'admin',
  'api',
  'tracking',
  'orders',
  'order',
  'shop',
  'password-reset',
  'password-set',
  'password',
  'approve',
  'activate',
  'active',
  'signup',
  'signin',
  'checkout',
  'apple',
  'apple-pay',
  'google',
  'google-pay',
  'cart',
  'recover',
  'recovery',
  ...Types.FORBIDDEN_SLUGS,
];

Types.FORBIDDEN_SLUGS_STORE = [
  // Global
  'admin',
  'api',
  'blog',
  'cdn',
  'client',
  'clients',
  'help',
  'support',
  'status',
  'www',
  'store',
  'stores',
  'shop',
  'shops',
  'payment',
  'payments',
  'billing',
  'wallet',
  'shipping',
  'mail',
  'email',
  'smtp',
  'ftp',
  'webmail',
  'services',
  'community',
  'forum',
  'news',
  'update',
  'updates',
  'download',
  'downloads',
  'assets',
  'static',
  'offer',
  'offers',
  'portal',
  'portals',
  'dashboard',
  'account',
  'accounts',
  'secure',
  'ssl',
  'vpn',
  'careers',
  'job',
  'jobs',
  'legal',
  'privacy',
  'terms',
  'about',
  'info',
  'docs',
  'developer',
  'developers',
  'faq',
  'features',
  'stats',
  'statistics',
  'report',
  'reports',
  'analytics',
  'tracking',
  'track',
  'feedback',
  'promo',
  'promotions',
  'marketing',
  'sales',
  'discount',
  'discounts',
  'coupon',
  'coupons',
  'deal',
  'deals',
  'orders',
  'order',
  'shop',
  'shops',
  'product',
  'products',
  'password-reset',
  'password-set',
  'password',
  'approve',
  'activate',
  'active',
  'signup',
  'signin',
  'reseller',
  'referral',
  'checkout',
  'apple',
  'apple-pay',
  'google',
  'google-pay',
  'cart',
  'recover',
  'recovery',
  // Marketplaces english
  'fashion',
  'food',
  'electronics',
  'books',
  'sports',
  'beauty',
  'health',
  'toys',
  'jewelry',
  'home',
  'garden',
  'pets',
  'music',
  'movies',
  'games',
  'art',
  'crafts',
  'automotive',
  'baby',
  'office',
  // Marketplaces serbian
  'moda',
  'klopa',
  'elektronika',
  'knjige',
  'sport',
  'lepota',
  'zdravlje',
  'igracke',
  'nakit',
  'kuca',
  'basta',
  'ljubimci',
  'muzika',
  'filmovi',
  'igre',
  'umetnost',
  'rukotvorine',
  'automobili',
  'bebe',
  'kancelarija',
  ...Types.FORBIDDEN_SLUGS_PAGE,
];

// console.log(Types.FORBIDDEN_SLUGS_STORE);

Types.COUNTRIES_DIAL_CODES = Types.COUNTRIES.reduce(
  (agr, country) => {
    country.dialCodes.forEach((dialCode, i) => {
      agr.push({
        value: dialCode,
        label: `+${dialCode} (${country.name})${i > 0 ? ` ${i}` : ''}`,
      });
    });
    return agr;
  },
  [],
);

Object.assign(Types, TypesHelpers.createValues('STORE_PRODUCT_TYPE', (
  Types.STORE_TYPE_ITEMS.map(storeType => ({
    id: storeType.productType,
    storeType: storeType.id,
    label: `${storeType.label} Product`,
  }))
)));

Types.STORE_TYPE_TO_STORE_PRODUCT_TYPE_MAP = Types.STORE_TYPE_ITEMS.reduce(
  (agr, storeType) => {
    agr[storeType.id] = storeType.productType;
    return agr;
  },
  {},
);

Object.assign(
  Types,
  Types.createValues('ANALYTICS_EVENTS', [{
    id: 'CART_OPEN',
    label: 'Open Cart',
  }, {
    id: 'CART_ADD',
    label: 'Add To Cart',
  }, {
    id: 'CART_REMOVE',
    label: 'Remove From Cart',
  }, {
    id: 'ORDER_SUBMIT',
    label: 'Submit Order',
  }, {
    id: 'ORDER_SUBMIT_SUCCESS',
    label: 'Submit Order Success',
  }, {
    id: 'PRODUCT_SEARCH',
    label: 'Search Products',
  }, {
    id: 'PRODUCT_OPEN',
    label: 'Open Product',
  }]),

  Types.createValues('CHARGED_TO', [{
    id: 'STORE',
    label: 'Store',
  }, {
    id: 'CUSTOMER',
    label: 'Customer',
  }]),

  Types.createValues('PRODUCT_ATTRIBUTE', [{
    id: 'FLAMMABLE',
    label: 'Flammable',
  }, {
    id: 'FRAGILE',
    label: 'Fragile',
  }, {
    id: 'WEAPON',
    label: 'Weapon',
  }, {
    id: 'BATTERY',
    label: 'Battery',
  }]),

  Types.createValues('PAYMENT_METHOD', [{
    id: 'CARD',
    label: 'Card Online',
    ...Types.createValues('METHODS', [{
      id: 'APPLE_PAY',
      label: 'Apple Pay',
      providers: ['CHECKOUT'],
      checkBrowserSupport: () => (
        window.location.protocol === 'https:'
        && window.ApplePaySession
        && window.ApplePaySession.canMakePayments()
      ),
    }]),
  }, {
    id: 'COD_CASH',
    label: 'Cash on Delivery',
  }, {
    id: 'COD_CARD',
    label: 'Card on Delivery',
  }, {
    id: 'FREE',
    label: 'Discounted',
    hidden: true,
  }], {
    getExtras: (result, name, values, config) => {
      result.PAYMENT_METHOD_TITLE_MAP = values.reduce(
        (agr, value) => {
          const id = config.getId(value);
          const title = value.title || config.getLabel(value);
          agr[id] = title;
          return agr;
        },
        [],
      );
    },
  }),

  Types.createValues('PAYMENT_CARD_PROVIDER', [{
    id: 'ALLSECURE',
    label: 'All Secure',
    online: true,
  }, {
    id: 'CHECKOUT',
    label: 'Checkout',
    online: true,
  }, {
    id: 'STRIPE',
    label: 'Stripe',
    online: true,
  }]),

  Types.createValues('PAYMENT_PROVIDER', [{
    id: 'ALLSECURE',
    label: 'All Secure',
    online: true,
  }, {
    id: 'CHECKOUT',
    label: 'Checkout',
    online: true,
  }, {
    id: 'STRIPE',
    label: 'Stripe',
    online: true,
  }]),

  Types.createValues('DRAFT_PURPOSE', [{
    id: 'POS',
    label: 'POS Checkout',
  }, {
    id: 'TEMPLATE',
    label: 'Template',
  }]),
);

Types.SHIPPING_SCHEDULED_HOUR = 0;

Object.assign(Types, TypesHelpers.createValues('SHIPPING_PRICING_METHOD', [{
  id: 'STANDARD',
  label: 'Standard',
}, {
  id: 'STANDARD_EXPRESS',
  label: 'Express',
}, {
  id: 'INTERNATIONAL',
  label: 'International (Standard)',
  international: true,
}, {
  id: 'INTERNATIONAL_EXPRESS',
  label: 'International (Express)',
  international: true,
}]));

Object.assign(Types, TypesHelpers.createValues('SHIPPING_PROVIDER', [{
  id: 'MOZHE_SHOP',
  label: 'Mozhe',
  param: 'mozhe-shop',
  description: 'Mozhe team handles the shipping for you.',
  methods: [{
    id: 'MOZHE_SHOP_STANDARD',
    pricingMethod: 'STANDARD',
    label: 'Standard',
    durationLabel: '2-3 days',
  }, {
    id: 'MOZHE_SHOP_STANDARD_EXPRESS',
    pricingMethod: 'STANDARD_EXPRESS',
    label: 'Express',
    durationLabel: '1 days',
  }, {
    id: 'MOZHE_SHOP_INTERNATIONAL',
    pricingMethod: 'INTERNATIONAL',
    label: 'International (Standard)',
    international: true,
  }, {
    id: 'MOZHE_SHOP_INTERNATIONAL_EXPRESS',
    pricingMethod: 'INTERNATIONAL_EXPRESS',
    label: 'International (Express)',
    durationLabel: '1-2 days',
    international: true,
  }],
  supportManualReadyForPickup: true,
  supportScheduledDelivery: true,
}, {
  id: 'STORE',
  label: 'Store Delivery',
  param: 'store',
  description: 'Store handles shipping internally.',
  store: true,
  methods: [{
    id: 'STORE_STANDARD',
    pricingMethod: 'STANDARD',
    label: 'Store Standard',
    durationLabel: '2-3 days',
  }, {
    id: 'STORE_EXPRESS',
    pricingMethod: 'STANDARD_EXPRESS',
    label: 'Store Express',
    durationLabel: '1 days',
  }, {
    id: 'STORE_INTERNATIONAL',
    pricingMethod: 'INTERNATIONAL',
    label: 'Store International (Standard)',
    international: true,
  }, {
    id: 'STORE_INTERNATIONAL_EXPRESS',
    pricingMethod: 'INTERNATIONAL_EXPRESS',
    label: 'Store International (Express)',
    durationLabel: '1-2 days',
    international: true,
  }],
  supportManualReadyForPickup: true,
  supportScheduledDelivery: true,
}, {
  id: 'STORE_RUSH',
  label: 'Store Zone Delivery',
  param: 'store-rush',
  description: 'Store own zone delivery.',
  methods: [{
    id: 'STORE_RUSH',
    label: 'Store Zone Delivery',
    durationLabel: '1-3 days',
  }],
}, {
  id: 'SELF_PICKUP',
  label: 'Self Pickup',
  param: 'self-pickup',
  description: 'Customer will pickup the goods in person.',
  supportManualReadyForPickup: true,
  methods: [{
    id: 'SELF_PICKUP',
    label: 'Self Pickup',
  }],
}, {
  id: 'POS',
  label: 'POS Checkout',
  param: 'pos',
  methods: [{
    id: 'POS',
    label: 'POS Checkout',
  }],
  description: 'Customer checks out at the register.',
}, {
  id: 'ABSTRACT',
  label: 'No Shipping',
  param: 'abstract',
  description: 'Providing services and selling non tangible goods.',
  abstract: true,
  methods: [{
    id: 'ABSTRACT',
    label: 'No Shipping',
  }],
}].map(shippingProvider => ({
  ...shippingProvider,
  abstract: !!shippingProvider.abstract,
  deprecated: !!shippingProvider.deprecated,
  description: shippingProvider.description || '',
  methods: shippingProvider.methods.map(shippingMethod => ({
    ...shippingMethod,
    store: !!shippingProvider.store,
    provider: shippingProvider.id,
    deprecated: !!shippingMethod.deprecated || !!shippingProvider.deprecated,
    durationLabel: shippingMethod.durationLabel || null,
  })),
}))));

Object.assign(Types, Types.SHIPPING_PROVIDER_ITEMS.reduce(
  (agr, shippingProvider) => {
    agr.SHIPPING_METHOD_ITEMS.push(...shippingProvider.methods);
    if (!shippingProvider.deprecated) {
      agr.SHIPPING_PROVIDER_USED.push(shippingProvider.id);
    }
    if (!shippingProvider.abstract) {
      agr.SHIPPING_PROVIDER_TANGIBLE.push(shippingProvider.id);
    }
    if (shippingProvider.store) {
      agr.SHIPPING_PROVIDER_STORE.push(shippingProvider.id);
    }
    agr.SHIPPING_PROVIDER_DESCRIPTIONS.push(shippingProvider.description);
    agr.SHIPPING_PROVIDER_DESCRIPTIONS_MAP[shippingProvider.id] = (
      shippingProvider.description
    );
    [
      [
        'supportManualReadyForPickup',
        'SHIPPING_PROVIDER_MANUAL_READY_FOR_PICKUP_SUPPORT',
      ], [
        'supportScheduledDelivery',
        'SHIPPING_PROVIDER_SCHEDULED_DELIVERY_SUPPORT',
      ],
    ].forEach(([key, agrKey]) => {
      if (shippingProvider[key]) {
        agr[`${agrKey}`].push(shippingProvider.id);
        agr[`${agrKey}_MAP`][shippingProvider.id] = true;
      } else {
        agr[`${agrKey}_MAP`][shippingProvider.id] = false;
      }
    });
    agr.SHIPPING_PROVIDER_METHOD_MAP[shippingProvider.id] = (
      shippingProvider.methods.map(shippingMethod => shippingMethod.id)
    );
    shippingProvider.methods.forEach((shippingMethod) => {
      agr.SHIPPING_METHOD_PROVIDER_MAP[
        shippingMethod.id
      ] = shippingProvider.id;
      if (shippingMethod.pricingMethod) {
        agr.SHIPPING_METHOD_USING_PRICING.push(shippingMethod.id);
        agr.SHIPPING_METHOD_USING_PRICING_ITEMS.push(shippingMethod);
        agr.SHIPPING_METHOD_PRICING_METHOD_MAP[shippingMethod.id] = (
          shippingMethod.pricingMethod
        );
        if (!agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_MAP[
          shippingProvider.id
        ]) {
          agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_MAP[
            shippingProvider.id
          ] = {};
          agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_ITEMS_MAP[
            shippingProvider.id
          ] = {};
        }
        agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_MAP[shippingProvider.id][
          shippingMethod.pricingMethod
        ] = shippingMethod.id;
        agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_ITEMS_MAP[
          shippingProvider.id
        ][
          shippingMethod.pricingMethod
        ] = shippingMethod;
      }
    });
    return agr;
  },
  {
    SHIPPING_METHOD_ITEMS: [],
    SHIPPING_PROVIDER_STORE: [],
    SHIPPING_PROVIDER_USED: [],
    SHIPPING_PROVIDER_TANGIBLE: [],
    SHIPPING_PROVIDER_DESCRIPTIONS: [],
    SHIPPING_PROVIDER_DESCRIPTIONS_MAP: {},
    SHIPPING_PROVIDER_MANUAL_READY_FOR_PICKUP_SUPPORT: [],
    SHIPPING_PROVIDER_MANUAL_READY_FOR_PICKUP_SUPPORT_MAP: {},
    SHIPPING_PROVIDER_SCHEDULED_DELIVERY_SUPPORT: [],
    SHIPPING_PROVIDER_SCHEDULED_DELIVERY_SUPPORT_MAP: {},
    SHIPPING_PROVIDER_METHOD_MAP: {},
    SHIPPING_METHOD_PROVIDER_MAP: {},
    SHIPPING_METHOD_USING_PRICING: [],
    SHIPPING_METHOD_USING_PRICING_ITEMS: [],
    SHIPPING_METHOD_PRICING_METHOD_MAP: {},
    SHIPPING_PRICING_METHOD_PROVIDER_METHOD_MAP: {},
    SHIPPING_PRICING_METHOD_PROVIDER_METHOD_ITEMS_MAP: {},
  },
));

Object.assign(Types, TypesHelpers.createValues('SHIPPING_METHOD', (
  Types.SHIPPING_METHOD_ITEMS
)));

Object.assign(Types, Types.SHIPPING_METHOD_ITEMS.reduce(
  (agr, shippingMethod) => {
    if (!shippingMethod.deprecated) {
      agr.SHIPPING_METHOD_USED.push(shippingMethod.id);
    }
    agr.SHIPPING_METHOD_DURATION_LABELS.push(shippingMethod.durationLabel);
    agr.SHIPPING_METHOD_DURATION_LABELS_MAP[shippingMethod.id] = (
      shippingMethod.durationLabel
    );
    return agr;
  },
  {
    SHIPPING_METHOD_USED: [],
    SHIPPING_METHOD_DURATION_LABELS: [],
    SHIPPING_METHOD_DURATION_LABELS_MAP: {},
  },
));

Object.assign(Types, Types.createValues('SHIPPING_STORAGE', [{
  id: 'LOCAL',
  label: 'Local',
}, {
  id: 'WAREHOUSE',
  label: 'Mozhe Warehouse',
  labelShort: 'Warehouse',
}, {
  id: 'ABSTRACT',
  label: 'Abstract',
  abstract: true,
}].map(shippingStorage => ({
  ...shippingStorage,
  labelShort: shippingStorage.labelShort || shippingStorage.label,
  abstract: !!shippingStorage.abstract,
}))));

Object.assign(Types, Types.SHIPPING_STORAGE_ITEMS.reduce(
  (agr, shippingStorage) => {
    if (!shippingStorage.abstract) {
      agr.SHIPPING_STORAGE_TANGIBLE.push(shippingStorage.id);
    }
    agr.SHIPPING_STORAGE_LABELS_SHORT.push(shippingStorage.labelShort);
    agr.SHIPPING_STORAGE_LABELS_SHORT_MAP[shippingStorage.id] = (
      shippingStorage.labelShort
    );
    return agr;
  },
  {
    SHIPPING_STORAGE_TANGIBLE: [],
    SHIPPING_STORAGE_LABELS_SHORT: [],
    SHIPPING_STORAGE_LABELS_SHORT_MAP: {},
  },
));

Object.assign(Types, TypesHelpers.createValues('SHIPPING_STATUS', [{
  id: 'PICKUP',
  label: 'Picking up',
  translationKey: 'shippingStatusPickup',
}, {
  id: 'PROGRESS',
  label: 'Delivery in progress',
  translationKey: 'shippingStatusProgress',
}, {
  id: 'ATTEMPT',
  label: 'Delivery attempted',
  translationKey: 'shippingStatusAttempt',
}, {
  id: 'REJECT',
  label: 'Delivery rejected by customer',
  translationKey: 'shippingStatusReject',
}, {
  id: 'RETURN',
  label: 'Delivery returning to sender', // ???
  translationKey: 'shippingStatusReturn',
}, {
  id: 'SUCCESS',
  label: 'Delivery successful',
  translationKey: 'shippingStatusSuccess',
}, {
  id: 'FAILED',
  label: 'Delivery failed',
  translationKey: 'shippingStatusFailed',
}, {
  id: 'PARCELBOX_REDIRECT',
  label: 'Redirected to Parcelbox',
  translationKey: 'shippingStatusParcelboxRedirect',
}]));

Types.SHIPPING_STATUS_ITEMS.forEach(({ translationKey }) => {
  Types.LANGUAGE_DICTIONARY_KEYS.push(translationKey);
});

Object.assign(Types, TypesHelpers.createValues('ORDER_STATUS', [{
  id: 'INVALID',
  label: 'Invalid',
  unlistable: true,
}, {
  id: 'INVALID_PAYMENT',
  label: 'Payment Failed',
  unlistableSeller: true,
}, {
  id: 'INVALID_APPROVAL',
  label: 'Denied',
}, {
  id: 'INVALID_SHIPPING',
  label: 'Invalid Shipping',
}, {
  id: 'DRAFT',
  label: 'Draft',
}, {
  id: 'PAYMENT',
  label: 'Pending Payment',
  unlistable: true,
}, {
  id: 'APPROVAL',
  label: 'Pending Approval',
}, {
  id: 'NEW',
  label: 'New',
}, {
  id: 'DELIVERY',
  label: 'On Delivery',
}, {
  id: 'DELIVERED',
  label: 'Delivered',
}, {
  id: 'FAILED',
  label: 'Failed',
}].map(orderStatus => ({
  ...orderStatus,
  unlistable: !!orderStatus.unlistable || false,
  unlistableSeller: !!orderStatus.unlistable || !!orderStatus.unlistableSeller,
}))));

Object.assign(Types, Types.ORDER_STATUS_ITEMS.reduce(
  (agr, orderStatus) => {
    if (!orderStatus.unlistable) {
      agr.ORDER_STATUS_LISTABLE.push(orderStatus.id);
      if (!orderStatus.unlistableSeller) {
        agr.ORDER_STATUS_LISTABLE_SELLER.push(orderStatus.id);
      }
    }
    return agr;
  },
  {
    ORDER_STATUS_LISTABLE: [],
    ORDER_STATUS_LISTABLE_SELLER: [],
    ORDER_STATUS_CHART: [
      ['NEW', 'APPROVAL'],
      ['DELIVERY'],
      ['DELIVERED'],
      ['FAILED', 'INVALID_APPROVAL'],
    ],
    ORDER_STATUS_CHART_LABELS: [
      'New',
      'In Delivery',
      'Delivered',
      'Failed',
    ],
  },
));

Types.ORDER_STATUS_CHART_AVAILABLE = Types.ORDER_STATUS_CHART.reduce(
  (agr, statuses) => {
    agr.push(...statuses);
    return agr;
  },
  [],
);

Types.DEFAULT_WORKING_HOURS = new Array(7).join('.').split('.').map(
  // eslint-disable-next-line no-unused-vars
  (_, i) => ([10, 22]),
);

Types.MOZHE_TRN = null;

Object.assign(Types, Types.createValues('FEATURED_PRODUCT_BEHAVIOUR', [{
  id: 'POSITION',
  label: 'Position',
}, {
  id: 'SIZE',
  label: 'Size',
}]));

Types.IMAGE_SIZING = [
  'COVER',
  'CONTAIN',
];

Types.IMAGE_SIZING_LABELS = [
  'Cover',
  'Contain',
];

Types.IMAGE_SIZING_LABELS_MAP = Types.IMAGE_SIZING.reduce(
  (agr, imageSize, index) => {
    agr[imageSize] = Types.IMAGE_SIZING_LABELS[index];
    return agr;
  },
  {},
);

Types.IMAGE_SIZING_PRODUCT = [
  'DEFAULT',
  'COVER',
  'CONTAIN',
];

Types.IMAGE_SIZING_PRODUCT_LABELS = [
  'Default',
  'Cover',
  'Contain',
];

Types.IMAGE_SIZING_PRODUCT_LABELS_MAP = Types.IMAGE_SIZING_PRODUCT.reduce(
  (agr, imageSize, index) => {
    agr[imageSize] = Types.IMAGE_SIZING_PRODUCT_LABELS[index];
    return agr;
  },
  {},
);

Object.assign(Types, Types.createValues('SOCIAL_MEDIA_NETWORK', [{
  id: 'TWITTER',
  label: 'Twitter',
}, {
  id: 'FACEBOOK',
  label: 'Facebook',
}, {
  id: 'TIKTOK',
  label: 'Tik Tok',
}, {
  id: 'WHATSAPP',
  label: 'WhatsApp',
}, {
  id: 'LINKEDIN',
  label: 'LinkedIn',
}, {
  id: 'INSTAGRAM',
  label: 'Instagram',
}, {
  id: 'YOUTUBE',
  label: 'Youtube',
}, {
  id: 'SNAPCHAT',
  label: 'Snapchat',
}]))

Object.assign(Types, Types.createValues('PRODUCTS_UPLOAD_BATCH_STATUS', [{
  id: 'NEW',
  label: 'New',
}, {
  id: 'READY',
  label: 'Ready',
}, {
  id: 'GENERATING',
  label: 'Generating Products',
}, {
  id: 'GALLERY',
  label: 'Generating Products Gallery',
}, {
  id: 'DONE',
  label: 'Done',
}]));

Object.assign(Types, Types.createValues('PRODUCTS_IMPORT_STATUS', [{
  id: 'NEW',
  label: 'New',
}, {
  id: 'READY',
  label: 'Queued',
}, {
  id: 'IMPORTING',
  label: 'Importing',
}, {
  id: 'DONE',
  label: 'Done',
}]));

Object.assign(Types, TypesHelpers.createValues('NEWSLETTER_PROVIDER', [{
  id: 'MAILCHIMP',
  label: 'MailChimp',
  param: 'mailChimp',
}]));

Object.assign(Types, Types.createValues('ONBOARDING_STEPS', [{
  id: 'STORE',
  label: 'Store',
  // eslint-disable-next-line max-len
  description: 'Configure your store basics here, including store name, industry, and store domain/link. These settings define your store and help customers find you online. If you need help at any point, you can contact our support team from the chat widget below.',
}, {
  id: 'BRAND',
  label: 'Brand',
  // eslint-disable-next-line max-len
  description: 'This is your brand page, where you can customize your brand colors, logo, and favicon. These elements create a visual identity for your brand and help you stand out from the competition.',
// }, {
//   id: 'THEME',
//   label: 'Theme',
//   // eslint-disable-next-line max-len
//   description: 'Configure your website theme here, including light/dark mode, button/icon roundness, and transition style. These customizations can enhance user experience and reflect your brand\'s style.',
// }, {
//   id: 'FONTS',
//   label: 'Fonts',
//   // eslint-disable-next-line max-len
//   description: 'Customize your website\'s font family, weight, text transform, and letter spacing for the title, body, and label fonts.Choose from a wide selection of fonts in the Google Fonts library.',
}, {
  id: 'SOCIAL',
  label: 'Social Media',
  // eslint-disable-next-line max-len
  description: 'Configure your social media links here for all major networks to connect with customers and build a strong online presence.',
}, {
  id: 'CATEGORIES',
  label: 'Categories',
  // eslint-disable-next-line max-len
  description: 'Set up your website categories here to organize your products or services logically and improve the user experience.',
}, {
//   id: 'COLLECTIONS',
//   label: 'Collections',
// }, {
  id: 'LEGAL',
  label: 'Legal',
  // eslint-disable-next-line max-len
  description: 'Configure your legal documents and TRN number here to comply with local laws and establish your business identity.',
}, {
  id: 'BANK',
  label: 'Bank',
  // eslint-disable-next-line max-len
  description: 'Configure your bank details here, including bank name, branch, IBAN, account number, and holder name. Providing accurate information can help ensure timely payments.',
}, {
  id: 'CONTRACT',
  label: 'Terms and Conditions',
  // eslint-disable-next-line max-len
  description: 'On this page, you can read and agree to our Terms and Conditions.',
}, {
  id: 'END',
  label: 'End',
  description: 'You\'ve finished the setup! 🎉',
  }]));

Object.assign(Types, Types.createValues('ONBOARDING_DOMAIN_OPTIONS', [{
  id: 'ALREADY_PURCHASED',
  label: 'Already own one',
}, {
  id: 'WANT_TO_PURCHASE',
  label: 'Purchase with Mozhe®',
}, {
  id: 'NOT_INTERESTED',
  label: 'Not Interested',
}]));

Object.assign(Types, Types.createValues('SALE_SUMMARY_TYPE', [{
  id: 'STORE',
  label: 'Store',
}, {
  id: 'CUSTOMER',
  label: 'Customer',
}, {
  id: 'STORE_CUSTOMER',
  label: 'Customer per store',

}]));

// eslint-disable-next-line max-len
Types.NETLIFY_WEBHOOK_URL_BUILD_HOMEPAGE = 'https://api.netlify.com/build_hooks/5eea10c8d8ca06e053ce9983';

Types.getSimpleAnalyticsHostname = (storeId, env) => {
  env = (
      isUndefined(env)
    ? (
        process.env.REACT_APP_DXTOOLS_ENV
        || process.env.VITE_APP_DXTOOLS_ENV
        || process.env.DXTOOLS_ENV
      )
    : (env === null || env === '')
    ? 'production'
    : undefined
  );
  return `${storeId}${
    env && env.length && env !== 'production'
    ? `.${env}`
    : ''
  }.store.shop.mozhe.rs`;
};

Types.CHECKOUT_COM_LOCALIZATIONS = [{
  label: 'Arabic',
  value: 'AR',
}, {
  label: 'Chinese Simplified',
  value: 'ZH-CH',
}, {
  label: 'Chinese Traditional (Hong Kong)',
  value: 'ZH-HK',
}, {
  label: 'Chinese Traditional (Taiwan)',
  value: 'ZH-TW',
}, {
  label: 'Danish',
  value: 'DA-DK',
}, {
  label: 'Dutch',
  value: 'NL-NL',
}, {
  label: 'English',
  value: 'EN-GB',
}, {
  label: 'Filipino',
  value: 'FIL-PH',
}, {
  label: 'Finnish',
  value: 'FI-FI',
}, {
  label: 'French',
  value: 'FR-FR',
}, {
  label: 'German',
  value: 'DE-DE',
}, {
  label: 'Hindi',
  value: 'HI-IN',
}, {
  label: 'Indonesian',
  value: 'ID-ID',
}, {
  label: 'Italian',
  value: 'IT-IT',
}, {
  label: 'Japanese',
  value: 'JA-JP',
}, {
  label: 'Korean',
  value: 'KO-KR',
}, {
  label: 'Malay',
  value: 'MS-MY',
}, {
  label: 'Norwegian Bokmål',
  value: 'NB-NO',
}, {
  label: 'Spanish',
  value: 'ES-ES',
}, {
  label: 'Swedish',
  value: 'SV-SE',
}, {
  label: 'Thai',
  value: 'TH-TH',
}, {
  label: 'Vietnamese',
  value: 'VI-VN',
}];

Object.assign(Types, TypesHelpers.createValues(
  'DEXPRESS_STATUS',
  dexpressStatus,
  {
    keyLabel: 'name',
  },
));

Object.assign(Types, TypesHelpers.createValues(
  'GLS_STATUS',
  glsStatus,
  {
    keyLabel: 'statusMessage',
  },
));

Object.assign(Types, TypesHelpers.createValues('PRODUCT_SHIPPING_CATEGORY', [{
 id: 'TEXTILE',
 label: 'Tekstil',
 labelEn: 'Textile',
}, {
 id: 'CAR_PART',
 label: 'Auto delovi',
 labelEn: 'Car parts',
}, {
 id: 'TECHNICS',
 label: 'Tehnika',
 labelEn: 'Electronics',
}, {
 id: 'DOCUMENT',
 label: 'Dokumenta',
 labelEn: 'Documents',
}, {
 id: 'BABY_ITEMS',
 label: 'Bebi oprema',
 labelEn: 'Baby items',
}, {
 id: 'COSMETICS',
 label: 'Kozmetika',
 labelEn: 'Cosmetics',
}, {
 id: 'FOOTWEAR',
 label: 'Obuća',
 labelEn: 'Footwear',
}, {
 id: 'MARKETING_MATERIAL',
 label: 'Reklamni materijal',
 labelEn: 'Marketing material',
}, {
 id: 'BOOK',
 label: 'Knjige',
 labelEn: 'Books',
}, {
 id: 'FOOD',
 label: 'Prehrana',
 labelEn: 'Food',
}, {
 id: 'SPORTSWEAR',
 label: 'Sportska oprema',
 labelEn: 'Sportswear',
}, {
 id: 'PLASTICS',
 label: 'Plastični asortiman',
 labelEn: 'Plastic assortment',
}, {
 id: 'METAL',
 label: 'Metalna roba',
 labelEn: 'Metal goods',
}, {
 id: 'TOOLS',
 label: 'Alat',
 labelEn: 'Tools',
}, {
  id: 'OTHER',
  label: 'Ostalo',
  labelEn: 'Other',
}]));

Types.SYSTEM_COUNTRY_ITEMS.forEach(({ currency }) => {
  Types.LANGUAGE_DICTIONARY_KEYS.push(`currency_${currency}`);
});

Types.DICTIONARY_OBJECT = {
  ...dictionary,
  shipping: Types.SHIPPING_STATUS_ITEMS.map(item => ({
    key: item.translationKey,
    translations: {
      en: item.label,
    },
  })),
};

Types.DICTIONARY = (Object.values(Types.DICTIONARY_OBJECT).reduce(
  (agr, group) => {
    agr.push(...group)
    return agr;
  },
  [],
));

Types.EMAIL_ROUNDNESS = {
  NONE: {
    xs: '0px',
    s: '0px',
    m: '0px',
    l: '0px',
    xl: '0px',
    full: '0px',
  },
  ROUNDED: {
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '32px',
    full: '9999px',
  },
  ROUND: {
    xs: '9999px',
    s: '9999px',
    m: '16px',
    l: '32px',
    xl: '64px',
    full: '9999px',
  },
};

Types.EMAIL_COLORS = {
  LIGHT: {
    brand: '#ff345a',
    accent: '#ff345a',
    buttonBackground: '#ff345a',
    buttonForeground: '#ffffff',
    background: '#f5f5f5',
    cardBackground: '#ffffff',
    cardBorder: '#f5f5f5',
    itemBackground: '#ffffff ',
    itemBorder: '#f5f5f5',
    title: '#111111',
    text: '#555555',
    label: '#111111',
    details: '#777777',
    copy: '#888888',
  },
  DARK: {
    brand: '#ff345a',
    accent: '#ff345a',
    buttonBackground: '#ff345a',
    buttonForeground: '#111111',
    background: '#111111',
    cardBackground: '#222222',
    cardBorder: '#222222',
    itemBackground: '#333333',
    itemBorder: '#333333',
    title: '#ffffff',
    text: '#dddddd',
    label: '#ffffff',
    details: '#777777',
    copy: '#888888',
  },
};

Object.assign(Types, Types.createValues('WAREHOUSE_PACKAGE_TYPE', [{
  id: 'BASIC',
  label: 'Basic',
}, {
  id: 'BRANDED',
  label: 'Branded',
}, {
  id: 'PREMIUM',
  label: 'Premium',
}, {
  id: 'CUSTOM',
  label: 'Custom',
  settings: false,
}]));

Object.assign(Types, Types.createValues('FISCAL_RECEIPT_VAT_TYPE', [{
  id: 'А',
  label: 'A (0%, Nije obveznik PDV-a)',
}, {
  id: 'Ђ',
  label: 'Ђ (20%, Opšta stopa)',
}, {
  id: 'Е',
  label: 'Е (10%, Posebna stopa)',
}, {
  id: 'Г',
  label: 'Г (0%, Oslobođeno PDV-a)',
}]));

Object.assign(Types, Types.createValues('INVOICE_TYPE', [{
  id: 'INVOICE',
  label: 'Invoice',
  invoice: true,
  payable: true,
}, {
  id: 'RECEIPT',
  label: 'Receipt',
  invoice: true,
  payable: false,
}, {
  id: 'STATEMENT',
  label: 'Statement',
  invoice: false,
  payable: true,
}]));

Object.assign(Types, Types.createValues('INVOICE_STATUS', [{
  id: 'DRAFT',
  label: 'Draft',
}, {
  id: 'PENDING',
  label: 'Pending',
}, {
  id: 'PAID',
  label: 'Paid',
}, {
  id: 'CANCELLED',
  label: 'Cancelled',
}]));

Object.assign(Types, Types.createValues('INVOICE_REFERENCE', [{
  id: 'SERVICES',
  key: 'OU',
  label: 'Services',
  labelRs: 'Ostale Usluge',
  paymentPurposePreviousMonth: true,
}, {
  id: 'SUBSCRIPTION_MONTHLY',
  key: 'MP',
  label: 'Monthly Subscription',
  labelRs: 'Mesečna Pretplata',
}, {
  id: 'SUBSCRIPTION_ANNUAL',
  key: 'GP',
  label: 'Annual Subscription',
  labelRs: 'Godišnja Pretplata',
}, {
  id: 'COMMISSION',
  key: 'OP',
  label: 'Commission',
  labelRs: 'Obračunata Provizija',
  paymentPurposePreviousMonth: true,
}, {
  id: 'ADDITONAL_EXPENSES',
  key: 'DT',
  label: 'Additional Expenses',
  labelRs: 'Dodatni Troškovi',
  paymentPurposePreviousMonth: true,
}]));

Types.CONSTANTS = {};

Types.CONSTANTS.StoreType = Types.createConstant(
  Types.STORE_TYPE,
);
Types.CONSTANTS.StoreProductType = Types.createConstant(
  Types.STORE_PRODUCT_TYPE,
);
Types.CONSTANTS.StoreSubscriptionDuration = Types.createConstant(
  Types.STORE_SUBSCRIPTION_DURATION,
);
Types.CONSTANTS.StoreSubscriptionMode = Types.createConstant(
  Types.STORE_SUBSCRIPTION_MODE,
);
Types.CONSTANTS.ProductKind = Types.createConstant(
  Types.PRODUCT_KIND,
);
Types.CONSTANTS.OrderStatus = Types.createConstant(
  Types.ORDER_STATUS,
);
Types.CONSTANTS.ShippingPricingMethod = Types.createConstant(
  Types.SHIPPING_PRICING_METHOD,
);
Types.CONSTANTS.ShippingMethod = Types.createConstant(
  Types.SHIPPING_METHOD,
);
Types.CONSTANTS.ShippingProvider = Types.createConstant(
  Types.SHIPPING_PROVIDER,
);
Types.CONSTANTS.ShippingStorage = Types.createConstant(
  Types.SHIPPING_STORAGE,
);
Types.CONSTANTS.PaymentMethod = Types.createConstant(
  Types.PAYMENT_METHOD,
);
Types.CONSTANTS.PaymentCardProvider = Types.createConstant(
  Types.PAYMENT_CARD_PROVIDER,
);
Types.CONSTANTS.PaymentProvider = Types.createConstant(
  Types.PAYMENT_PROVIDER,
);
Types.CONSTANTS.ImageSizing = Types.createConstant(
  Types.IMAGE_SIZING,
);
Types.CONSTANTS.ImageSizingProduct = Types.createConstant(
  Types.IMAGE_SIZING_PRODUCT,
);

export default Types;
